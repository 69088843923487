import { render, staticRenderFns } from "./PasswordRecoveryFirstStep.vue?vue&type=template&id=65ce1556&scoped=true&"
import script from "./PasswordRecoveryFirstStep.vue?vue&type=script&lang=js&"
export * from "./PasswordRecoveryFirstStep.vue?vue&type=script&lang=js&"
import style0 from "./PasswordRecoveryFirstStep.vue?vue&type=style&index=0&id=65ce1556&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ce1556",
  null
  
)

export default component.exports