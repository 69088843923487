<template>
  <div class="recovery-first-step">
    <ui-input
        :label="$t('enter-email-to-recover-password')"
        v-model="email"
        :error="$v.email.$error || catchError"
        :error-text='getLoginErrorText'
        @input="catchError = false"
    />
    <div class="recovery-first-step__line"></div>
    <ui-button :disabled="!email || $v.email.$anyError" @click="submit">
      {{ $t('continue') }}
    </ui-button>
    <ui-button @click="$emit('back')" color="outline">
      {{ $t('cancel-1') }}
    </ui-button>
  </div>
</template>


<script>
import { email, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "PasswordRecoveryFirstStep",
  props: {
    userEmail: {
      type: String,
      default: ""
    }
  },
  inject: ['form'],
  components: {
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
  },
  validations() {
    return {
      email: {
        required,
        email
      },
    }
  },
  data() {
    return {
      email: '',
      catchError: false
    }
  },
  mounted() {
    document.addEventListener('keydown', this.enter)
    this.userEmail ? this.email = this.userEmail : this.email = ''
  },

  // destroyed() {
  //   document.removeEventListener('keydown',  this.enter, false);
  // },

  computed: {
    getLoginErrorText() {
      if(!this.$v.email.email.$anyError && !this.catchError) {
        return this.$t('error-email')
      }

      return this.$t('user-does-not-exist')
    },
  },

  methods: {
    ...mapActions(['reset']),
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.reset({
          email: this.email
        }).then(() => {
          this.form.email = this.email
          this.$emit('continue')
        }).catch(() => {
          this.catchError = true
        })
      }
    },
    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recovery-first-step {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__line {
    border-bottom: 1px solid #FFFFFF;
  }
}
</style>
